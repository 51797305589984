import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { ConfigProvider } from "antd";
import { MDXRenderer } from "gatsby-plugin-mdx";

import "../styles/katnh.scss";

export default function PageTemplate({ data: { mdx } }) {
  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: "#c9d22a",
      },
    });
  });
  return (
    <div className="styles-katnh">
      <MDXRenderer>{mdx.body}</MDXRenderer>
      <div data-iframe-height></div>
      <script
        type="text/javascript"
        src="/js/iframeresizer/4.2.10/iframeResizer.contentWindow.min.js"
      ></script>
    </div>
  );
}

export const pageQuery = graphql`
  query KatnhQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        client
        source
        edit
      }
    }
  }
`;
